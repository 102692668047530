/* noto sans */
@font-face {
    font-family: 'Noto-Black';
    src: url('./assets/fonts/NotoSansKR-Black.otf') format('opentype');
}

@font-face {
    font-family: 'Noto-Bold';
    src: url('./assets/fonts/NotoSansKR-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Noto-Medium';
    src: url('./assets/fonts/NotoSansKR-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'Noto-Regular';
    src: url('./assets/fonts/NotoSansKR-Regular.otf');
}

@font-face {
    font-family: 'Noto-Light';
    src: url('./assets/fonts/NotoSansKR-Light.otf') format('opentype');
}

/* futura  */
@font-face {
    font-family: 'Futura';
    src: url('./assets/fonts/FuturaStdBold.otf') format('opentype');
}

@font-face {
    font-family: 'Futura PT';
    src: url('./assets/fonts/Futura\ Heavy\ font.ttf') format('truetype');
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    /* 1rem = 10px */
    font-size: 62.5%;
}

body {
    min-width: 1150px;
    padding-right: 0px !important;
}

h1,
h2,
h3,
h4,
p {
    white-space: pre-line !important;
}
.white-space-nowrap {
    white-space: nowrap !important;
}
@media all and (max-width: 768px) {
    body {
        min-width: 375px;
    }
    .text_nowrap {
        white-space: nowrap !important;
    }
    .text_center {
        text-align: center;
    }
    span {
        white-space: pre-line !important;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-input-placeholder {
    font-family: 'Noto-Light';
}
input::-moz-placeholder {
    font-family: 'Noto-Light';
}
input:-ms-input-placeholder {
    font-family: 'Noto-Light';
}
input:-moz-placeholder {
    font-family: 'Noto-Light';
}
input::placeholder {
    font-family: 'Noto-Light';
}
